import React, { useMemo } from "react"

import SubMenuBlock, { SubMenuBlockProps } from "./SubMenuBlock"

const SubMenus = ({ subMenus }: { subMenus: SubMenuBlockProps[] }) => {
  const descriptionSubMenu = useMemo(
    () =>
      subMenus[subMenus.length - 1].type === "description"
        ? subMenus.pop()
        : undefined,
    [subMenus]
  )

  return (
    <>
      <div className="col-start-1 col-end-5 grid grid-cols-4 gap-grid-xs sm:gap-grid-sm md:col-start-1 md:col-end-5 md:gap-grid-md lg:col-start-1 lg:col-end-10 lg:grid-cols-9 lg:gap-grid-lg xl:gap-grid-xl">
        {subMenus.map((subMenuBlock, subMenuBlockIndex) => (
          <SubMenuBlock
            key={`navigation-submenu-${subMenuBlockIndex}`}
            {...subMenuBlock}
          />
        ))}
      </div>
      <div className="col-start-1 col-end-5 grid md:col-start-5 md:col-end-7 lg:col-start-10 lg:col-end-13">
        {descriptionSubMenu && (
          <SubMenuBlock
            key={`navigation-submenu-description-block`}
            {...descriptionSubMenu}
          />
        )}
      </div>
    </>
  )
}

export default SubMenus
