/*Resolve image aspectRatio to a tailwind aspect ratio class in order to
 * enforce aspect ratio on css level (if no defined widthds)
 * as per lighthouse recommendation
 */
export const getAspectRatioClass = (
  aspectRatio: "1:1" | "4:3" | "3:4" | "16:9" | "fullWidth" | "auto"
): string => {
  switch (aspectRatio) {
    case "1:1":
      return "aspect-[1/1]"
    case "4:3":
      return "aspect-[4/3]"
    case "3:4":
      return "aspect-[3/4]"
    case "16:9":
      return "aspect-[16/9]"
    case "auto":
      return "aspect-auto"
    case "fullWidth":
    default:
      return ""
  }
}
