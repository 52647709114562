import { useLocation } from "@reach/router"
import classNames from "classnames"
import React, { useCallback, useEffect, useRef, useState } from "react"

import { NotificationType } from "./types"
import ErrorAlertIcon from "../components/elements/V2/Icons/alerts/ErrorIcon"
import InfoAlertIcon from "../components/elements/V2/Icons/alerts/InfoIcon"
import SuccessAlertIcon from "../components/elements/V2/Icons/alerts/SuccessIcon"
import WarningAlertIcon from "../components/elements/V2/Icons/alerts/WarningIcon"
import IconToast, { ToastProps } from "../components/Toast"
import { Color } from "../constants/V2/color"

import { getAccentColor, getStrokeColorClass } from "@utils/V2/color"

const NOTIFICATION_LIFETIME = 2000

const autoHideDuration = {
  success: 2000,
  warning: 4000,
  error: 6000,
  info: 3000,
}

const toastConfig = {
  success: {
    variant: "success",
    color: Color.Green,
    icon: SuccessAlertIcon,
    autoHideDuration: autoHideDuration.success,
  },
  info: {
    variant: "info-dark",
    color: Color.Blue,
    icon: InfoAlertIcon,
    autoHideDuration: autoHideDuration.info,
  },
  warning: {
    variant: "warning",
    color: Color.Yellow,
    icon: WarningAlertIcon,
    autoHideDuration: autoHideDuration.warning,
  },
  danger: {
    variant: "alert",
    color: Color.Pink,
    icon: ErrorAlertIcon,
    autoHideDuration: autoHideDuration.error,
  },
}

interface Props {
  type: NotificationType
  message?: string
  title: string
  onClose?: () => void
}

const Toast = ({ type, message, title, onClose }: Props) => {
  const [hide, setHide] = useState(false)
  const { pathname } = useLocation()
  const locationWithError = useRef(pathname)

  const text = `${title}${message ? `, ${message}` : ""}`
  const config = toastConfig[type] || toastConfig.info
  const textColor =
    type === "danger" ? Color.Orange : getAccentColor(config.color)

  const IconComponent = config.icon

  const visibilityTimeout = config.autoHideDuration

  const notificationRef = useRef<HTMLDivElement>(null)

  const disappearTimerRef = useRef<number | undefined>()

  const disappear = useCallback((timestamp: number) => {
    if (!disappearTimerRef.current) {
      disappearTimerRef.current = timestamp
    }
    if (timestamp - disappearTimerRef.current >= NOTIFICATION_LIFETIME) {
      disappearTimerRef.current = undefined
      if (
        (notificationRef.current?.style.opacity == "0" ||
          notificationRef.current?.style.opacity) == "" &&
        onClose
      ) {
        onClose()
      }
    } else {
      requestAnimationFrame(disappear)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const timeoutTimerRef = useRef<number | undefined>()

  const timeout = useCallback(
    (timestamp: number) => {
      if (!timeoutTimerRef.current) {
        timeoutTimerRef.current = timestamp
      }
      if (
        timestamp - timeoutTimerRef.current >= visibilityTimeout &&
        timeoutTimerRef !== undefined
      ) {
        timeoutTimerRef.current = undefined
        disappearTimerRef.current = undefined

        setHide(true)
        requestAnimationFrame(disappear)
      } else {
        requestAnimationFrame(timeout)
      }
    },
    [disappear, visibilityTimeout]
  )

  const reset = useCallback(() => {
    timeoutTimerRef.current = undefined
    disappearTimerRef.current = undefined
    setHide(false)
  }, [])

  useEffect(() => {
    if (locationWithError.current !== pathname) {
      setHide(true)
    } else {
      reset()
      requestAnimationFrame(timeout)
    }

    return () => {
      setHide(false)
    }
  }, [type, reset, timeout, pathname])

  return (
    <div
      ref={notificationRef}
      className={classNames("fixed bottom-0 left-0 z-50 w-full", {
        "duration-2000 opacity-0 transition-opacity": hide,
      })}
    >
      <IconToast
        text={text}
        onClick={onClose}
        variant={config.variant as ToastProps["variant"]}
        icon={<IconComponent className={getStrokeColorClass(textColor)} />}
        className={classNames(
          "justify-between rounded-none",
          "px-gutter-xs sm:px-gutter-sm md:px-gutter-md lg:px-gutter-lg xl:px-gutter-xl"
        )}
      />
    </div>
  )
}

export default Toast
