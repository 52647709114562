import classNames from "classnames"
import React from "react"
import type { PropsWithChildren } from "react"

type Props = {
  className?: string
}

const Grid = ({ children, className, ...props }: PropsWithChildren<Props>) => {
  return (
    <div
      className={classNames(
        "grid grid-cols-4 items-stretch gap-grid-xs sm:gap-grid-sm md:grid-cols-6 md:gap-grid-md lg:grid-cols-12 lg:gap-grid-lg xl:gap-grid-xl",
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
}

export default Grid
