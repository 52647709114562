import { MainLink } from "../../components/V2/Navigation/NavHeading"

import { getUrlFromStoryblokLink, linkIsNotNull } from "@utils/storyblok"

/**
 * Map storyblok navigation main links object array to the format
 * accepted by the Navigation component
 */
export const mapNavigationMainLinks = (
  mainLinks: Storyblok.NavigationMainLink[]
): MainLink[] => {
  return mainLinks.map((mainLink) => ({
    label: mainLink.label,
    link: linkIsNotNull(mainLink.link)
      ? getUrlFromStoryblokLink(mainLink.link)
      : undefined,
    subMenuBlocks: mainLink.subMenuBlocks.map((subMenuBlock) =>
      subMenuBlock.component === "navigationDescriptionSubMenu"
        ? {
            type: "description",
            title: subMenuBlock.headerText,
            description: subMenuBlock.bodyText,
            link: getUrlFromStoryblokLink(subMenuBlock.linkUrl),
            linkText: subMenuBlock.linkText,
          }
        : {
            type: "links",
            title: subMenuBlock.headerText,
            links: subMenuBlock.links.map((subMenuLink) => ({
              ...subMenuLink,
              link: getUrlFromStoryblokLink(subMenuLink.link),
            })),
          }
    ),
  }))
}
