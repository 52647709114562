import { Color } from "../../constants/V2/color"

export const getStrokeColorClass = (color?: Color): string => {
  switch (color) {
    case Color.Green:
      return "stroke-green"
    case Color.MintGreen:
      return "stroke-mint-green"
    case Color.Orange:
      return "stroke-orange"
    case Color.Yellow:
      return "stroke-yellow"
    case Color.Blue:
      return "stroke-blue"
    case Color.LightBlue:
      return "stroke-light-blue"
    case Color.Black:
      return "stroke-black"
    case Color.White:
      return "stroke-white"
    case Color.Charcoal:
      return "stroke-charcoal"
    case Color.LightGray:
      return "stroke-light-gray"
    case Color.Cream:
      return "stroke-cream"
    case Color.Pink:
      return "stroke-pink"
    default:
      return ""
  }
}

export const getFillColorClass = (color?: Color): string => {
  switch (color) {
    case Color.Green:
      return "fill-green"
    case Color.MintGreen:
      return "fill-mint-green"
    case Color.Orange:
      return "fill-orange"
    case Color.Yellow:
      return "fill-yellow"
    case Color.Blue:
      return "fill-blue"
    case Color.LightBlue:
      return "fill-light-blue"
    case Color.Black:
      return "fill-black"
    case Color.White:
      return "fill-white"
    case Color.Charcoal:
      return "fill-charcoal"
    case Color.LightGray:
      return "fill-light-gray"
    case Color.Cream:
      return "fill-cream"
    case Color.Pink:
      return "fill-pink"
    default:
      return ""
  }
}

export const getTextColorClass = (color?: Color): string => {
  switch (color) {
    case Color.Green:
      return "text-green"
    case Color.MintGreen:
      return "text-mint-green"
    case Color.Orange:
      return "text-orange"
    case Color.Yellow:
      return "text-yellow"
    case Color.Blue:
      return "text-blue"
    case Color.LightBlue:
      return "text-light-blue"
    case Color.Black:
      return "text-black"
    case Color.White:
      return "text-white"
    case Color.Charcoal:
      return "text-charcoal"
    case Color.LightGray:
      return "text-light-gray"
    case Color.Cream:
      return "text-cream"
    case Color.Pink:
      return "text-pink"
    default:
      return ""
  }
}

export const getTextHoverColorClass = (color?: Color): string => {
  switch (color) {
    case Color.Green:
      return "hover:text-green group-hover:text-green focus-visible:text-green group-focus-visible:text-green"
    case Color.MintGreen:
      return "hover:text-mint-green group-hover:text-mint-green focus-visible:text-mint-green group-focus-visible:text-mint-green"
    case Color.Orange:
      return "hover:text-orange group-hover:text-orange focus-visible:text-orange group-focus-visible:text-orange"
    case Color.Yellow:
      return "hover:text-yellow group-hover:text-yellow focus-visible:text-yellow group-focus-visible:text-yellow"
    case Color.Blue:
      return "hover:text-blue group-hover:text-blue focus-visible:text-blue group-focus-visible:text-blue"
    case Color.LightBlue:
      return "hover:text-light-blue group-hover:text-light-blue focus-visible:text-light-blue group-focus-visible:text-light-blue"
    case Color.Black:
      return "hover:text-black group-hover:text-black focus-visible:text-black group-focus-visible:text-black"
    case Color.White:
      return "hover:text-white group-hover:text-white focus-visible:text-white group-focus-visible:text-white"
    case Color.Charcoal:
      return "hover:text-charcoal group-hover:text-charcoal focus-visible:text-charcoal group-focus-visible:text-charcoal"
    case Color.LightGray:
      return "hover:text-light-gray group-hover:text-light-gray focus-visible:text-light-gray group-focus-visible:text-light-gray"
    case Color.Cream:
      return "hover:text-cream group-hover:text-cream focus-visible:text-cream group-focus-visible:text-cream"
    case Color.Pink:
      return "hover:text-pink group-hover:text-pink focus-visible:text-pink group-focus-visible:text-pink"
    default:
      return ""
  }
}

export const getBackgroundColorClass = (color: Color): string => {
  switch (color) {
    case Color.Green:
      return "bg-green"
    case Color.MintGreen:
      return "bg-mint-green"
    case Color.Orange:
      return "bg-orange"
    case Color.Yellow:
      return "bg-yellow"
    case Color.Blue:
      return "bg-blue"
    case Color.LightBlue:
      return "bg-light-blue"
    case Color.Black:
      return "bg-black"
    case Color.Charcoal:
      return "bg-charcoal"
    case Color.LightGray:
      return "bg-light-gray"
    case Color.Cream:
      return "bg-cream"
    case Color.Pink:
      return "bg-pink"
    case Color.White:
      return "bg-white"
  }
}

export const getBackgroundHoverColorClass = (color: Color): string => {
  switch (color) {
    case Color.Green:
      return "hover:bg-green focus-visible:bg-green"
    case Color.MintGreen:
      return "hover:bg-mint-green focus-visible:bg-mint-green"
    case Color.Orange:
      return "hover:bg-orange focus-visible:bg-orange"
    case Color.Yellow:
      return "hover:bg-yellow focus-visible:bg-yellow"
    case Color.Blue:
      return "hover:bg-blue focus-visible:bg-blue"
    case Color.LightBlue:
      return "hover:bg-light-blue focus-visible:bg-light-blue"
    case Color.Black:
      return "hover:bg-black focus-visible:bg-black"
    case Color.Charcoal:
      return "hover:bg-charcoal focus-visible:bg-charcoal"
    case Color.LightGray:
      return "hover:bg-light-gray focus-visible:bg-light-gray"
    case Color.Cream:
      return "hover:bg-cream focus-visible:bg-cream"
    case Color.Pink:
      return "hover:bg-pink focus-visible:bg-pink"
    case Color.White:
      return "hover:bg-white focus-visible:bg-white"
  }
}

export const getBorderColorClass = (color: Color): string => {
  switch (color) {
    case Color.Green:
      return "border-green"
    case Color.MintGreen:
      return "border-mint-green"
    case Color.Orange:
      return "border-orange"
    case Color.Yellow:
      return "border-yellow"
    case Color.Blue:
      return "border-blue"
    case Color.LightBlue:
      return "border-light-blue"
    case Color.Black:
      return "border-black"
    case Color.Charcoal:
      return "border-charcoal"
    case Color.LightGray:
      return "border-light-gray"
    case Color.Cream:
      return "border-cream"
    case Color.Pink:
      return "border-pink"
    case Color.White:
      return "border-white"
  }
}

export const getGradientFromColorClass = (color: Color): string => {
  switch (color) {
    case Color.Green:
      return "from-green"
    case Color.MintGreen:
      return "from-mint-green"
    case Color.Orange:
      return "from-orange"
    case Color.Yellow:
      return "from-yellow"
    case Color.Blue:
      return "from-blue"
    case Color.LightBlue:
      return "from-light-blue"
    case Color.Black:
      return "from-black"
    case Color.Charcoal:
      return "from-charcoal"
    case Color.LightGray:
      return "from-light-gray"
    case Color.Cream:
      return "from-cream"
    case Color.Pink:
      return "from-pink"
    case Color.White:
      return "from-white"
  }
}

export const getGradientToColorClass = (color: Color): string => {
  switch (color) {
    case Color.Green:
      return "to-green"
    case Color.MintGreen:
      return "to-mint-green"
    case Color.Orange:
      return "to-orange"
    case Color.Yellow:
      return "to-yellow"
    case Color.Blue:
      return "to-blue"
    case Color.LightBlue:
      return "to-light-blue"
    case Color.Black:
      return "to-black"
    case Color.Charcoal:
      return "to-charcoal"
    case Color.LightGray:
      return "to-light-gray"
    case Color.Cream:
      return "to-cream"
    case Color.Pink:
      return "to-pink"
    case Color.White:
      return "to-white"
  }
}

export const getAccentColor = (color: Color) => {
  switch (color) {
    case Color.Green:
    case Color.Charcoal:
    case Color.Blue:
    case Color.Black:
    case Color.Orange:
    case Color.Yellow:
      return Color.White
    case Color.MintGreen:
    case Color.White:
    case Color.LightBlue:
    case Color.LightGray:
    case Color.Cream:
    case Color.Pink:
      return Color.Charcoal
    default:
      return Color.Charcoal
  }
}

export const getSliderThumbColorClass = (color: Color) => {
  switch (color) {
    case Color.Black:
      return "[&::-webkit-slider-thumb]:bg-black [&::-moz-range-thumb]:bg-black"
    case Color.White:
      return "[&::-webkit-slider-thumb]:bg-white [&::-moz-range-thumb]:bg-white"
    default:
      return "[&::-webkit-slider-thumb]:bg-black [&::-moz-range-thumb]:bg-black"
  }
}

export const getButtonColor = (color: Color) => {
  switch (color) {
    case Color.Green:
    case Color.Charcoal:
    case Color.Black:
    case Color.Orange:
    case Color.Yellow:
    case Color.MintGreen:
    case Color.White:
    case Color.LightBlue:
    case Color.LightGray:
    case Color.Cream:
    case Color.Pink:
      return Color.Blue
    case Color.Blue:
      return Color.Charcoal
    default:
      return Color.Blue
  }
}

export const getShadowColorClass = (color: Color): string => {
  switch (color) {
    case Color.Green:
      return "shadow-green"
    case Color.MintGreen:
      return "shadow-mint-green"
    case Color.Orange:
      return "shadow-orange"
    case Color.Yellow:
      return "shadow-yellow"
    case Color.Blue:
      return "shadow-blue"
    case Color.LightBlue:
      return "shadow-light-blue"
    case Color.Black:
      return "shadow-black"
    case Color.Charcoal:
      return "shadow-charcoal"
    case Color.LightGray:
      return "shadow-light-gray"
    case Color.Cream:
      return "shadow-cream"
    case Color.Pink:
      return "shadow-pink"
    case Color.White:
      return "shadow-white"
  }
}
