import classNames from "classnames"
import React from "react"

import Link from "../../../components/elements/Link"
import { Color } from "../../../constants/V2/color"
import TextButton from "../../elements/V2/Buttons/TextButton"
import ArrowIcon from "../../elements/V2/Icons/ArrowIcon"
import Typography from "../../elements/V2/Typography"

interface DescriptionSubMenu {
  type: "description"
  title: string
  description: string
  link?: string
  linkText?: string
}

interface LinksSubMenu {
  type: "links"
  title: string
  links: {
    label: string
    link: string | Storyblok.Link
    badgeText?: string
    badgeColor?: Color.Pink | Color.LightBlue
  }[]
}

export type SubMenuBlockProps = DescriptionSubMenu | LinksSubMenu

const SubMenuBlock = (props: SubMenuBlockProps) => {
  return (
    <div
      className={
        props.type === "links" ? "col-span-4 md:col-span-3" : "col-span-1"
      }
    >
      <Typography
        color={Color.Charcoal}
        font="grotesk"
        weight={props.type === "description" ? "medium" : "book"}
        size={props.type === "description" ? "lead-lg" : "body-md"}
        className={
          props.type === "description" ? "mb-16 mt-12 md:mt-0" : "mb-16"
        }
        text={props.title}
      />

      {props.type === "description" ? (
        <>
          <Typography
            color={Color.Charcoal}
            font="grotesk"
            weight="book"
            size="body-md"
            text={props.description}
          />
          {props.linkText && props.link ? (
            <TextButton
              className="mb-20 mt-20 md:mb-0 md:mt-32"
              color={Color.Blue}
              text={props.linkText}
              linkUrl={props.link}
              style="icon"
              icon={<ArrowIcon />}
            />
          ) : undefined}
        </>
      ) : null}

      {props.type === "links" ? (
        <div className="flex flex-col gap-[12px] pl-20 md:pl-0">
          {props.links.map((link, index) => {
            const isBadge = !!(link.badgeText && link.badgeColor)
            return (
              <Link
                key={`link-${index}`}
                link={link.link}
                className={classNames(
                  "group flex w-fit items-center gap-8",
                  {
                    "-ml-10 rounded-20 p-[5px] pl-10 duration-200": isBadge,
                  },
                  {
                    [link.badgeColor === Color.LightBlue
                      ? "bg-light-blue hover:bg-blue"
                      : "bg-pink hover:bg-orange"]: isBadge,
                  }
                )}
              >
                <Typography
                  color={Color.Charcoal}
                  font="grotesk"
                  weight="medium"
                  size="body-md"
                  text={link.label}
                  className={classNames(
                    "pt-[2px] duration-200 ease-in-out",
                    isBadge ? "group-hover:text-white" : "group-hover:text-blue"
                  )}
                />
                {isBadge && (
                  <div className="rounded-20 bg-white px-[7px] pt-[2px]">
                    <Typography
                      text={link.badgeText as string}
                      weight="medium"
                      font="grotesk"
                      color={
                        link.badgeColor === Color.LightBlue
                          ? Color.Blue
                          : Color.Orange
                      }
                      size="subscript-md"
                    />
                  </div>
                )}
              </Link>
            )
          })}
        </div>
      ) : null}
    </div>
  )
}

export default SubMenuBlock
