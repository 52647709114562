import classNames from "classnames"
import React from "react"

import { SubMenuBlockProps } from "./SubMenuBlock"
import SubMenus from "./SubMenus"
import Grid from "../../elements/V2/Grid"

import Link from "@components/elements/Link"

export interface MainLink {
  label: string
  link?: string | Storyblok.Link
  subMenuBlocks?: SubMenuBlockProps[]
}

type Props = {
  mainLink: MainLink
  className?: string
  mode: "light" | "dark"
  scrolled: boolean
  isMenuActive: boolean
  handleClick: () => void
  handleHover: () => void
  isSubMenuActive: boolean
  isMenuOpenOnMobile: boolean
}

const NavHeading = ({
  mainLink,
  className,
  mode,
  scrolled,
  isMenuActive,
  handleClick,
  handleHover,
  isSubMenuActive,
  isMenuOpenOnMobile,
}: Props) => {
  const commonClasses = classNames(
    className,
    {
      "px-gutter-xs sm:px-gutter-sm md:px-gutter-md": isMenuOpenOnMobile,
    },
    {
      "text-black lg:text-white": mode === "dark" && !scrolled && !isMenuActive,
    },
    {
      "text-black": (mode === "light" && scrolled) || isMenuActive,
    }
  )

  return (
    <React.Fragment>
      {mainLink.link ? (
        <Link
          className={commonClasses}
          link={mainLink.link}
          onClick={() => handleClick()}
          onMouseEnter={() => handleHover()}
          onFocus={() => handleHover()}
        >
          {mainLink.label}
          <img
            src="/icons/V2/chevron-down.svg"
            alt={`Open ${mainLink.label}`}
            width={12}
            height={9}
            className={classNames(
              "transform transition-transform duration-200 ease-in-out lg:hidden",
              {
                "rotate-180": isSubMenuActive,
              },
              { hidden: !mainLink.subMenuBlocks?.length }
            )}
          />
        </Link>
      ) : (
        <p
          className={commonClasses}
          onClick={() => handleClick()}
          onMouseEnter={() => handleHover()}
          onFocus={() => handleHover()}
        >
          {mainLink.label}
          <img
            src="/icons/V2/chevron-down.svg"
            alt={`Open ${mainLink.label}`}
            width={12}
            height={9}
            className={classNames(
              "transform transition-transform duration-200 ease-in-out lg:hidden",
              {
                "rotate-180": isSubMenuActive,
              },
              { hidden: !mainLink.subMenuBlocks?.length }
            )}
          />
        </p>
      )}

      {isMenuOpenOnMobile && (
        <div
          className={classNames(
            "border-b border-b-black border-opacity-10 lg:border-b-transparent",
            {
              "transition-all duration-300 ease-in-out": isMenuOpenOnMobile,
            },
            {
              "mx-gutter-xs sm:mx-gutter-sm md:mx-gutter-md":
                isSubMenuActive && isMenuOpenOnMobile,
            }
          )}
        />
      )}

      {mainLink.subMenuBlocks?.length ? (
        <div
          className={classNames(
            "left-0 z-50 max-h-0 w-full overflow-hidden transition-all duration-200 ease-in-out lg:pointer-events-none lg:absolute lg:mt-[22px] lg:max-h-max lg:px-gutter-lg lg:opacity-0 xl:px-gutter-xl",
            {
              "max-h-[800px] pb-24 lg:pointer-events-auto lg:opacity-100":
                isSubMenuActive,
            },
            {
              "bg-white lg:shadow-[0_16px_16px_-8px] lg:shadow-charcoal/20":
                scrolled || isMenuActive,
            }
          )}
        >
          <div
            className={
              "rounded-sm bg-white px-gutter-xs py-20 transition-height sm:px-gutter-sm md:px-gutter-md md:py-24 lg:-ml-32 lg:w-[calc(100%_+_64px)] lg:bg-transparent lg:px-32 lg:py-64"
            }
          >
            <Grid
              className={classNames(
                "transition-all duration-300",
                isSubMenuActive
                  ? "translate-y-0 opacity-100"
                  : "translate-y-1/3 opacity-0 lg:translate-y-1/2"
              )}
            >
              <SubMenus subMenus={mainLink.subMenuBlocks} />
            </Grid>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  )
}

export default NavHeading
