import BundleApi from "@yoco/shop-sdk/lib/api/BundleApi"
import CartApi from "@yoco/shop-sdk/lib/api/CartApi"
import ProductApi from "@yoco/shop-sdk/lib/api/ProductApi"
import HttpClient from "@yoco/shop-sdk/lib/HttpClient"

import { getUrlFromStoryblokLink } from "./storyblok"

import { isPurchasableBundle, PurchasableItem } from "@hooks/V2/storyblok"

const http = new HttpClient(`${process.env.GATSBY_SHOP_API_URL}`)
export const shop = {
  carts: new CartApi(http),
  products: new ProductApi(http),
  bundles: new BundleApi(http),
}

const isBrowser = typeof window !== "undefined"

export const localStorageAvailable = (): boolean => {
  if (!isBrowser) {
    return false
  }
  try {
    localStorage.setItem("testCookie", "testCookie")
    localStorage.removeItem("testCookie")
    return true
  } catch (e) {
    return false
  }
}

export const fetchCartId = (): string | null => {
  if (localStorageAvailable()) {
    return localStorage.getItem("cartId")
  }
  return null
}

export const storeCartId = (id: string): void => {
  if (localStorageAvailable()) {
    localStorage.setItem("cartId", id)
  }
}

export const itemAlreadyInCart = (item: PurchasableItem): boolean => {
  return isPurchasableBundle(item)
    ? bundleAlreadyInCart(item.bundleCode)
    : productAlreadyInCart(item.sku)
}

export const productAlreadyInCart = (sku: string): boolean => {
  const products = fetchCartProducts()
  const inCart = products?.find((product) => {
    // This is a hotfix for users who already have existing cart items without IDs
    if (!product.id) {
      clearCart()
      return false
    }

    if (!isStoredProduct(product)) return false
    return product.sku === sku
  })

  return inCart ? true : false
}

export const bundleAlreadyInCart = (bundleCode: string): boolean => {
  const products = fetchCartProducts()

  const inCart = products?.find((product) => {
    // This is a hotfix for users who already have existing cart items without IDs
    if (!product.id) {
      clearCart()
      return false
    }

    if (!isStoredBundle(product)) return false

    return product.bundleCode === bundleCode
  })

  return inCart ? true : false
}

export interface StoredItemBase {
  id: string
  quantity: number
}

export interface StoredProduct extends StoredItemBase {
  sku: string
  type: "product"
}

export interface StoredBundle extends StoredItemBase {
  bundleCode: string
  type: "bundle"
}

export type StoredItemType = "product" | "bundle"

export type StoredItem = StoredProduct | StoredBundle

export const isStoredProduct = (
  storedItem: StoredItem
): storedItem is StoredProduct => {
  return storedItem.type === "product"
}

export const isStoredBundle = (
  storedItem: StoredItem
): storedItem is StoredBundle => {
  return storedItem.type === "bundle"
}

export interface BundleConversionFields {
  bundleCode: string
  id: string
}
//TEMP conversion of old cart products into new
const getBundleCodeAndID = (name: string): BundleConversionFields => {
  switch (name) {
    case "Neo Touch + iPad stand":
      return {
        bundleCode: "NEO_TOUCH_AND_STAND",
        id: "7c80dc4f-80f4-42fc-a2b2-21dca51a59c4",
      }
    case "Complete POS solution":
      return {
        bundleCode: "COMPLETE_POS_SOLUTION",
        id: "3bd106b2-78a7-415e-bc36-4f35c5edcfa9",
      }
    case "Yoco Counter Bundle":
      return {
        bundleCode: "COUNTER_BUNDLE",
        id: "2139821b-81be-498d-ae3b-dd2a459859df",
      }
    case "Khumo Print":
    default:
      return {
        bundleCode: "KHUMO_PRINT_AND_STAND",
        id: "ca359efc-a65a-45a3-8dca-e6159c6ced34",
      }
  }
}

export const fetchCartProducts = ():
  | (StoredBundle | StoredProduct)[]
  | null => {
  if (localStorageAvailable()) {
    const products = localStorage.getItem("cartProducts")
    let mappedProducts = []
    //TEMP conversion of old cart products into new
    //remove in couple of days once all old carts are replaced
    if (products) {
      mappedProducts = JSON.parse(products)
        .map((product: any) => {
          //already in new structure
          if (product.type) {
            return product
          }

          //is bundle parent. convert to new bundle structure
          if (product.isBundleParent) {
            const bundleMappedFields = getBundleCodeAndID(product.bundleName)
            return {
              id: bundleMappedFields.id,
              quantity: product.quantity,
              bundleCode: bundleMappedFields.bundleCode,
              type: "bundle",
            }
          }

          //is a standalone product. convert to new bundle structure
          if (
            product.isBundleParent === undefined &&
            product.type === undefined
          ) {
            return {
              id: product.id,
              quantity: product.quantity,
              sku: product.sku,
              type: "product",
            }
          }
        })
        .filter((product: any) => {
          //remove all the unpacked lingering bundle items
          return product !== undefined
        })

      // store if any difference between new and old products
      if (mappedProducts.length !== JSON.parse(products)?.length) {
        localStorage.setItem("cartProducts", JSON.stringify(mappedProducts))
      }
    }

    return mappedProducts
  }

  return null
}

export const getProductName = (
  sdkProduct: YocoCom.SdkProduct | null,
  storyblokName: string
): string => {
  return sdkProduct?.short_name || storyblokName
}

export interface ProductPrice {
  price: string
  originalPrice?: string
}

export const getProductPrices = (
  product: YocoCom.SdkProduct | null,
  storyblokPrice: string,
  forceStoryblokPrice: boolean,
  storyblokPromotionPrice?: string
): ProductPrice => {
  if (forceStoryblokPrice || !product) {
    const storyblokPrices: ProductPrice = {
      price: storyblokPromotionPrice ? storyblokPromotionPrice : storyblokPrice,
    }
    if (storyblokPromotionPrice && parseFloat(storyblokPrice) > 0) {
      storyblokPrices["originalPrice"] = storyblokPrice
    }

    return storyblokPrices
  }

  const sdkPrices: ProductPrice = {
    price: product.unit_price,
  }

  if (parseFloat(product.original_unit_price) > 0) {
    sdkPrices["originalPrice"] = product.original_unit_price
  }

  return sdkPrices
}

export const getBundlePrices = (
  bundle: YocoCom.SdkBundle | null,
  storyblokPrice: string,
  forceStoryblokPrice: boolean,
  storyblokPromotionPrice?: string
): ProductPrice => {
  if (forceStoryblokPrice || !bundle) {
    const storyblokPrices: ProductPrice = {
      price: storyblokPromotionPrice ? storyblokPromotionPrice : storyblokPrice,
    }
    if (storyblokPromotionPrice && parseFloat(storyblokPrice) > 0) {
      storyblokPrices["originalPrice"] = storyblokPrice
    }

    return storyblokPrices
  }

  const sdkPrices: ProductPrice = {
    price: bundle.unit_price,
  }

  if (parseFloat(bundle.original_unit_price) > 0) {
    sdkPrices["originalPrice"] = bundle.original_unit_price
  }

  return sdkPrices
}

export const consolidateProduct = (
  productId: string,
  quantity: number,
  sdkProduct: YocoCom.SdkProduct,
  storyblokProduct: Storyblok.ProductConfig
): YocoCom.ConsolidatedProduct => {
  const productPrices = getProductPrices(
    sdkProduct,
    storyblokProduct.price,
    storyblokProduct.forceStoryblokPrice,
    storyblokProduct.promotionPrice
  )

  return {
    id: productId,
    sku: storyblokProduct.sku ?? sdkProduct.sku,
    name: sdkProduct.short_name ?? storyblokProduct.name,
    cartTitle:
      storyblokProduct.overrideCartTitle ||
      sdkProduct.short_name ||
      storyblokProduct.name,
    image: storyblokProduct?.image ?? sdkProduct.image,
    quantity: quantity,
    maxQuantity: sdkProduct.max_quantity_per_cart ?? undefined,
    price: productPrices.price,
    originalPrice: productPrices.originalPrice,
    detailLink: getUrlFromStoryblokLink(storyblokProduct.detailsLink),
    description: storyblokProduct.fullDescription ?? sdkProduct.description,
    galleryItems: storyblokProduct.galleryItems,
    thirdPartyReviews: sdkProduct.third_party_reviews,
    recommendedProducts: storyblokProduct.recommendedProducts,
    recommendedLabel: storyblokProduct.recommendedLabel,
    showRecommendedProducts: storyblokProduct.showRecommendedProducts,
    promotionStartDate: storyblokProduct.promotionStartDate,
    promotionEndDate: storyblokProduct.promotionEndDate,
    reasonNotAvailable: sdkProduct.reason_not_available,
    priceSideScript: storyblokProduct.priceSuffixLabel,
    priceSubScript: storyblokProduct.priceInfoText,
    type: "product",
  }
}

export const consolidateBundle = (
  bundleId: string,
  quantity: number,
  sdkBundle: YocoCom.SdkBundle,
  storyblokBundle: Storyblok.BundleConfig
): YocoCom.ConsolidatedBundle => {
  const bundlePrices = getBundlePrices(
    sdkBundle,
    storyblokBundle.price.toString(),
    storyblokBundle.forceStoryblokPrice,
    storyblokBundle.originalPrice
  )

  return {
    id: bundleId,
    bundleCode: storyblokBundle.code ?? sdkBundle.bundle_code,
    name: sdkBundle.short_name ?? storyblokBundle.name,
    cartTitle:
      storyblokBundle.overrideCartTitle ||
      sdkBundle.short_name ||
      storyblokBundle.name,
    image: storyblokBundle?.image ?? sdkBundle.image,
    quantity: quantity,
    maxQuantity: sdkBundle.max_quantity_per_cart ?? undefined,
    price: bundlePrices.price,
    originalPrice: bundlePrices.originalPrice,
    detailLink: getUrlFromStoryblokLink(storyblokBundle.shopPage),
    description: storyblokBundle.fullDescription ?? sdkBundle.description,
    galleryItems: storyblokBundle.galleryItems,
    recommendedProducts: storyblokBundle.recommendedProducts,
    recommendedLabel: storyblokBundle.recommendedLabel,
    showRecommendedProducts: storyblokBundle.showRecommendedProducts,
    promotionStartDate: storyblokBundle.promotionStartDate,
    promotionEndDate: storyblokBundle.promotionEndDate,
    reasonNotAvailable: sdkBundle.reason_not_available,
    type: "bundle",
  }
}

export const storeCartProducts = (products: StoredItemBase[]): void => {
  if (localStorageAvailable()) {
    localStorage.setItem("cartProducts", JSON.stringify(products))
  }
}

export const fetchCartPromoCode = (): string | null => {
  if (localStorageAvailable()) {
    return localStorage.getItem("promoCode")
  }
  return null
}

export const storeCartPromoCode = (promoCode: string): void => {
  if (localStorageAvailable()) {
    localStorage.setItem("promoCode", promoCode)
  }
}

export const clearCartPromoCode = (): void => {
  if (localStorageAvailable()) {
    localStorage.removeItem("promoCode")
  }
}

export const clearCartId = (): void => {
  if (localStorageAvailable()) {
    localStorage.removeItem("cartId")
  }
}

export const clearCart = (): void => {
  if (localStorageAvailable()) {
    localStorage.removeItem("cartId")
    localStorage.removeItem("cartProducts")
    localStorage.removeItem("promoCode")
  }
}

export const humanizeAddressText = (
  address: YocoCom.ShippingAddress
): string => {
  const addressList = []

  if (address.country) {
    addressList.push(address.country)
  }
  if (address.street) {
    addressList.push(address.street)
  }
  if (address.suburb) {
    addressList.push(address.suburb)
  }
  if (address.city) {
    addressList.push(address.city)
  }
  if (address.province) {
    addressList.push(address.province)
  }
  if (address.postalCode) {
    addressList.push(address.postalCode)
  }

  return addressList.join(", ")
}

export const isShopEnabled = (): boolean => {
  // This fetches a static setting determined at build time

  if (process.env.GATSBY_IS_SHOP_ENABLED === "true") {
    return true
  } else {
    return false
  }
}

export const isConsolidatedProduct = (
  cartItem: YocoCom.ConsolidatedCartItem
): cartItem is YocoCom.ConsolidatedProduct => {
  return cartItem.type === "product"
}

export const isConsolidatedBundle = (
  cartItem: YocoCom.ConsolidatedCartItem
): cartItem is YocoCom.ConsolidatedBundle => {
  return cartItem.type === "bundle"
}
