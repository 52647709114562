import React, { useContext } from "react"

import { CartContext } from "../store"

type Props = {
  numberOfItems?: number
}

export default function CartWidget({ numberOfItems = 0 }: Props) {
  const cartContext = useContext(CartContext)

  const toggleCartPreview = () => {
    if (!cartContext.cartDisabled) {
      cartContext.setCartPreviewOpen(!cartContext.cartPreviewOpen)
    }
  }

  return (
    <button
      type="button"
      className="flex items-center rounded-32 bg-blue px-16 py-12 text-body-md font-medium !leading-none text-white md:px-[18px] md:py-[14px] lg:px-20 lg:py-16"
      onClick={toggleCartPreview}
    >
      {/* // TODO: Use CartIcon instead of image */}
      <img
        src="/icons/cart/cart-icon.svg"
        alt="Cart icon"
        width={16}
        height={16}
        className="mr-[5px]"
      />{" "}
      <span className="ml-4 hidden lg:inline">Cart</span>
      <span className="-my-4 ml-4 rounded-24 bg-white px-12 py-4 text-black lg:ml-8">
        {numberOfItems}
      </span>
    </button>
  )
}
