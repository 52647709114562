import classNames from "classnames"
import React, { forwardRef } from "react"
import type { PropsWithChildren } from "react"

type Props = {
  id?: string
  className?: string
} & React.DOMAttributes<HTMLDivElement>

const Section = forwardRef<HTMLElement, PropsWithChildren<Props>>(
  ({ children, id, className, ...props }, ref) => {
    return (
      <section
        ref={ref}
        id={id ? id : undefined}
        className={classNames(
          "px-gutter-xs sm:px-gutter-sm md:px-gutter-md lg:px-gutter-lg xl:px-gutter-xl",
          className
        )}
        {...props}
      >
        {children}
      </section>
    )
  }
)

Section.displayName = "Section"

export default Section
