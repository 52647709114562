import { LocationProvider } from "@reach/router"
import React from "react"

import CartProvider from "./cart/store"
import CheckoutProvider from "./checkout/store"
import NavigationV2Provider from "./components/V2/Navigation/context"
import { NotificationProvider } from "./notifications/context"
interface RootElementProps {
  element: React.ReactElement[] | React.ReactElement
}

const WrapRootElement = ({ element }: RootElementProps) => {
  return (
    <LocationProvider>
      <NotificationProvider>
        <NavigationV2Provider>
          <CheckoutProvider>
            <CartProvider>{element}</CartProvider>
          </CheckoutProvider>
        </NavigationV2Provider>
      </NotificationProvider>
    </LocationProvider>
  )
}

export default WrapRootElement
