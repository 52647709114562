// MARK: Used in V2 start
export const storyblokUrlToInternalUrl = (url: string): string => {
  return url?.replace(process.env.GATSBY_STORYBLOK_ROOT || "", "") ?? ""
}

export const getUrlFromStoryblokLink = (link: Storyblok.Link): string => {
  if (link.linktype === "email") {
    return `mailto:${link.email}`
  }

  const urlReference = link.url || link.cached_url
  if (
    urlReference.includes("http") ||
    urlReference.includes("tel:") ||
    urlReference.startsWith("#")
  ) {
    return urlReference
  }
  return (
    // TODO: Check if prefix path still needed with V2 implementation
    `${process.env.GATSBY_PREFIX_PATH || ""}/${storyblokUrlToInternalUrl(
      urlReference
    )}${urlReference && urlReference.slice(-1) !== "/" ? "/" : ""}` || "/"
  )
}

export const linkIsNotNull = (link: Storyblok.Link | undefined): boolean => {
  return !!(link?.cached_url || link?.url || link?.email)
}
// MARK: Used in V2 end

export const linkIsExternalUrl = (link: Storyblok.Link | string): boolean => {
  const urlReference =
    typeof link === "string" ? link : link?.url || link?.cached_url
  const isEmail =
    (typeof link === "string" && link.includes("mailto:")) ||
    (typeof link !== "string" && link?.linktype === "email")

  return (
    isEmail || urlReference?.includes("http") || urlReference?.includes("tel:")
  )
}

export const isEditorWindow = (): boolean => {
  return window.location.pathname.includes("/editor/")
}

export const parseStoryblokDate = (date?: string): Date | null => {
  return date ? new Date(date.replace(" ", "T")) : null
}

export const setDummyCartData = () => {
  if (typeof window !== "undefined") {
    localStorage.setItem("cartId", "112eb12b-0444-49a8-9d7c-74cbd9a3dfb8")
    localStorage.setItem(
      "cartProducts",
      JSON.stringify([
        {
          id: "ca359efc-a65a-45a3-8dca-e6159c6ced34",
          bundleCode: "KHUMO_PRINT_AND_STAND",
          quantity: 1,
          type: "bundle",
        },
      ])
    )
  }
}
