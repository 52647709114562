import { useCallback } from "react"

import { useGetBundleByCode } from "@hooks/bundles"
import { useGetProductBySku } from "@hooks/products"
import { getBundlePrices, getProductPrices } from "@utils/shop"
import { getUrlFromStoryblokLink, linkIsNotNull } from "@utils/storyblok"
import type { NumberDisplayAdornmentPosition } from "components/elements/V2/NumberDisplay"

export interface Product {
  id: string
  sku: string
}
export interface Bundle {
  products: Product[]
  bundleId?: string
  bundleName?: string
}

export interface PurchasableItemBase {
  id: string
  bundleName?: string
}

export interface PurchasableProduct extends PurchasableItemBase {
  type: "product"
  sku: string
}

export interface PurchasableBundle extends PurchasableItemBase {
  type: "bundle"
  bundleCode: string
}

export type PurchasableItem = PurchasableProduct | PurchasableBundle

export const isPurchasableProduct = (
  PurchasableItem: PurchasableItem
): PurchasableItem is PurchasableProduct => {
  return PurchasableItem.type === "product"
}

export const isPurchasableBundle = (
  PurchasableItem: PurchasableItem
): PurchasableItem is PurchasableBundle => {
  return PurchasableItem.type === "bundle"
}

//TODO this mapping can be made unneccessary by making AddToCartButton work directly with consolidatedBundle | consolidatedProduct
export const useGetPurchasableItem = () => {
  //Create an object containing the fields needed on AddToCartButton for handling product | bundle calls
  const getPurchasableItem = useCallback(
    (
      id: string,
      object: Storyblok.ProductConfig | Storyblok.BundleConfig
    ): PurchasableItem => {
      if (object.component === "product") {
        const product = object as Storyblok.ProductConfig
        return {
          id: id,
          sku: product?.sku,
          type: "product",
        }
      } else {
        // Assume this is a bundle object
        const bundle = object as Storyblok.BundleConfig
        return {
          id: id,
          bundleCode: bundle?.code,
          type: "bundle",
        }
      }
    },
    []
  )

  return getPurchasableItem
}

export interface EcoSystemFields extends Partial<Storyblok.PillCTA> {
  name: string
  price?: string | number
  originalPrice?: number
  priceTerm?: string
  priceType: "price" | "percentage" | "custom"
  priceSideScript?: string
  priceSubScript?: string
  detailsLink?: Storyblok.Link
  shortDescription: string | undefined
  adornmentTextPosition: NumberDisplayAdornmentPosition
  adornmentText: string
  reasonNotAvailable?: string
  waitlistLink?: string
}

export const useGetEcoSystemObjectFields = () => {
  const getSdkProduct = useGetProductBySku()
  const getSdkBundle = useGetBundleByCode()
  const getEcoSystemObjectFields = useCallback(
    /**
     * This function accepts the following objects: Product; Package; Fee; Bundle;
     * and returns a consolidated object of common fields shared between these objects.
     *
     * These should be used for eco-system components that reference these objects
     * interchangeably.
     */
    (
      object:
        | Storyblok.Product
        | Storyblok.Package
        | Storyblok.Fee
        | Storyblok.Bundle
    ): EcoSystemFields => {
      if (object.content.component === "product") {
        const product = object as Storyblok.Product
        const sdkProduct = getSdkProduct(product.content.sku)

        const prices = getProductPrices(
          sdkProduct,
          product.content.price,
          product.content.forceStoryblokPrice,
          product.content.promotionPrice
        )

        //TODO these values should compare to sdk GetProductBySKU stuff
        return {
          name: product.content.name,
          price: prices?.price ? parseFloat(prices?.price) : undefined,
          originalPrice: prices?.originalPrice
            ? parseFloat(prices?.originalPrice)
            : undefined,
          priceTerm: "once-off",
          priceType: "price",
          priceSideScript: product.content.priceSuffixLabel,
          priceSubScript: product.content.priceInfoText,
          ctaText: product.content.buyNowText || "Buy Now",
          ctaLink: product.content.buyLink,
          detailsLink: product.content.detailsLink,
          shortDescription: product.content.shortDescription,
          adornmentTextPosition: "left",
          adornmentText: "R",
          reasonNotAvailable: sdkProduct?.reason_not_available,
          waitlistLink:
            product.content.waitlistLink &&
            linkIsNotNull(product.content.waitlistLink)
              ? getUrlFromStoryblokLink(product.content.waitlistLink)
              : undefined,
        }
      } else if (object.content.component === "bundle") {
        const bundle = object as Storyblok.Bundle
        const sdkBundle = getSdkBundle(bundle.content.code)
        const prices = getBundlePrices(
          sdkBundle,
          bundle.content.price.toString(),
          bundle.content.forceStoryblokPrice,
          bundle.content.price.toString()
        )

        return {
          name: bundle.content.name,
          price: prices?.price ? parseFloat(prices?.price) : undefined,
          originalPrice: prices?.originalPrice
            ? parseFloat(prices?.originalPrice)
            : undefined,
          priceTerm: "once-off",
          priceType: "price",
          priceSideScript: bundle.content.priceSidescript,
          priceSubScript: bundle.content.priceSubscript,
          ctaText: bundle.content.buyNowText || "Buy Now",
          ctaLink: bundle.content.shopPage,
          detailsLink: bundle.content.cataloguePage,
          shortDescription: bundle.content.shortDescription,
          adornmentTextPosition: "left",
          adornmentText: "R",
          reasonNotAvailable: sdkBundle?.reason_not_available,
          waitlistLink:
            bundle.content.waitlistLink &&
            linkIsNotNull(bundle.content.waitlistLink)
              ? getUrlFromStoryblokLink(bundle.content.waitlistLink)
              : undefined,
        }
      } else if (object.content.component === "fee_type") {
        const fee = object as Storyblok.Fee

        const price = fee.content.breakpoints.reduce(
          (prevValue, currentBreakpoint) => {
            return Math.max(
              prevValue,
              parseFloat(currentBreakpoint.chargePercentage || "0")
            )
          },
          0
        )

        return {
          name: fee.content.title,
          price: price,
          priceTerm: "excl. vat",
          priceType: "percentage",
          ctaText: "Buy Now",
          ctaLink: fee.content.pageLink,
          shortDescription: fee.content.shortDescription,
          adornmentTextPosition: "right",
          adornmentText: "%",
        }
      }
      // Assume this is a package object
      else {
        const yocoPackage = object as Storyblok.Package

        return {
          name: object.content.name,
          price:
            yocoPackage.content.priceType === "custom"
              ? yocoPackage.content.price
              : parseFloat(yocoPackage.content.price),
          priceTerm: yocoPackage.content.priceTerm,
          ctaText: "Buy Now",
          ctaLink: yocoPackage.content.pageLink,
          shortDescription: yocoPackage.content.shortDescription,
          priceType: yocoPackage.content.priceType,
          adornmentTextPosition:
            yocoPackage.content.priceType === "price" ? "left" : "right",
          adornmentText: yocoPackage.content.priceType === "price" ? "R" : "%",
        }
      }
    },
    [getSdkProduct, getSdkBundle]
  )

  return getEcoSystemObjectFields
}

export const useGetConsolidatedObjectFields = () => {
  const getSdkProduct = useGetProductBySku()
  const getSdkBundle = useGetBundleByCode()
  const getConsolidatedObjectFields = useCallback(
    /**
     * This function accepts the following objects: Product; Bundle;
     * and returns a consolidated object of common fields shared between these objects.
     *
     * These should be used for eco-system components that reference these objects
     * interchangeably.
     */
    (
      uuid: string,
      object: Storyblok.ProductConfig | Storyblok.BundleConfig,
      quantity: number
    ): YocoCom.ConsolidatedCartItem | undefined => {
      if (object.component === "product") {
        const product = object as Storyblok.ProductConfig
        const sdkProduct = getSdkProduct(product.sku)

        if (!sdkProduct) {
          return undefined
        }

        const prices = getProductPrices(
          sdkProduct,
          product.price,
          product.forceStoryblokPrice,
          product.promotionPrice
        )

        return {
          id: uuid,
          sku: product.sku ?? sdkProduct.sku,
          name: sdkProduct.short_name ?? product.name,
          cartTitle:
            product.overrideCartTitle || sdkProduct.short_name || product.name,
          image: product.image ?? sdkProduct.image,
          quantity: quantity,
          maxQuantity: sdkProduct?.max_quantity_per_cart ?? undefined,
          price: prices.price,
          originalPrice: prices.originalPrice,
          detailLink: getUrlFromStoryblokLink(product.detailsLink),
          waitlistLink:
            product.waitlistLink && linkIsNotNull(product.waitlistLink)
              ? getUrlFromStoryblokLink(product.waitlistLink)
              : undefined,
          description: product.shortDescription,
          galleryItems: product.galleryItems,
          priceSideScript: product.priceSuffixLabel,
          priceSubScript: product.priceInfoText,
          thirdPartyReviews: sdkProduct.third_party_reviews,
          recommendedProducts: product.recommendedProducts,
          recommendedLabel: product.recommendedLabel,
          showRecommendedProducts: product.showRecommendedProducts,
          recommendedProductDescription: product.recommendedProductDescription,
          promotionStartDate: product.promotionStartDate,
          promotionEndDate: product.promotionEndDate,
          reasonNotAvailable: sdkProduct.reason_not_available,
          type: "product",
        }
      } else {
        const bundle = object as Storyblok.BundleConfig
        const sdkBundle = getSdkBundle(bundle.code)

        if (!sdkBundle) {
          return undefined
        }

        const prices = getBundlePrices(
          sdkBundle,
          bundle.price.toString(),
          bundle.forceStoryblokPrice,
          bundle.originalPrice
        )

        return {
          id: uuid,
          bundleCode: bundle.code,
          name: bundle.name,
          cartTitle: bundle.overrideCartTitle || bundle.name,
          image: bundle.image,
          quantity: quantity,
          maxQuantity: sdkBundle?.max_quantity_per_cart ?? undefined,
          price: prices.price,
          originalPrice: prices.originalPrice,
          detailLink: getUrlFromStoryblokLink(bundle.shopPage),
          waitlistLink:
            bundle.waitlistLink && linkIsNotNull(bundle.waitlistLink)
              ? getUrlFromStoryblokLink(bundle.waitlistLink)
              : undefined,
          description: bundle.shortDescription,
          priceSideScript: bundle.priceSidescript,
          priceSubScript: bundle.priceSubscript,
          galleryItems: bundle.galleryItems,
          promotionStartDate: bundle.promotionStartDate,
          promotionEndDate: bundle.promotionEndDate,
          reasonNotAvailable: sdkBundle.reason_not_available,
          recommendedProducts: bundle.recommendedProducts,
          showRecommendedProducts: bundle.showRecommendedProducts,
          recommendedProductDescription: bundle.recommendedProductDescription,
          recommendedLabel: bundle.recommendedLabel,
          type: "bundle",
        }
      }
    },
    [getSdkProduct, getSdkBundle]
  )

  return getConsolidatedObjectFields
}
