import React from "react"

type SuccessIconProps = {
  alt?: string
  className?: string
}

const SuccessIcon = ({ className, alt }: SuccessIconProps) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      viewBox="0 0 30 30"
      xmlSpace="preserve"
      fillRule="evenodd"
      clipRule="evenodd"
      className={className}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="1.5"
      aria-labelledby={alt || ""}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        r={12.918}
        cx={16.751}
        cy={11.189}
        fill="none"
        strokeWidth="2.12"
        strokeOpacity="0.2"
        transform="translate(-.696 4.17) scale(.94148)"
      />
      <path
        strokeWidth="1.8"
        fill="currentColor"
        d="m9.76 16.034 2.466 2.47M19.86 10.903l-7.606 7.604"
      />
    </svg>
  )
}

export default SuccessIcon
