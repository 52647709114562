import React from "react"

type Props = {
  className?: string
  svgClassName: string
}

const SupportIcon = ({ svgClassName, className }: Props) => {
  return (
    <div className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#ffffff"
        xmlns="http://www.w3.org/2000/svg"
        className={svgClassName}
        strokeWidth={1.5}
      >
        <path d="M1 21a3.619 3.619 0 0 1 3.619-3.619h3.619a3.619 3.619 0 0 1 3.619 3.62M8.668 9.26a3.167 3.167 0 1 1-4.479 4.48 3.167 3.167 0 0 1 4.48-4.48M18.095 13.808a4.905 4.905 0 1 0-4.904-4.904" />
        <path d="M18.102 13.813a4.897 4.897 0 0 1-2.064-.454L13.02 14l.63-3.024a4.9 4.9 0 0 1-.457-2.072" />
        <path
          d="M15.8127 8.96464C15.8322 8.98417 15.8322 9.01583 15.8127 9.03536C15.7932 9.05488 15.7615 9.05488 15.742 9.03536C15.7225 9.01583 15.7225 8.98417 15.742 8.96464C15.7615 8.94512 15.7932 8.94512 15.8127 8.96464"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.0627 8.96464C18.0822 8.98417 18.0822 9.01583 18.0627 9.03536C18.0432 9.05488 18.0115 9.05488 17.992 9.03536C17.9725 9.01583 17.9725 8.98417 17.992 8.96464C18.0115 8.94512 18.0432 8.94512 18.0627 8.96464"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.3127 8.96464C20.3322 8.98417 20.3322 9.01583 20.3127 9.03536C20.2932 9.05488 20.2615 9.05488 20.242 9.03536C20.2225 9.01583 20.2225 8.98417 20.242 8.96464C20.2615 8.94512 20.2932 8.94512 20.3127 8.96464"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}

export default SupportIcon
