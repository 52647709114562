import React from "react"

type WarningIconProps = {
  alt?: string
  className?: string
}

const WarningIcon = ({ className, alt }: WarningIconProps) => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      viewBox="0 0 30 30"
      xmlSpace="preserve"
      fillRule="evenodd"
      clipRule="evenodd"
      className={className}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="1.5"
      aria-labelledby={alt || ""}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        r={12.918}
        cx={16.751}
        cy={11.189}
        fill="none"
        strokeWidth="2.12"
        strokeOpacity="0.2"
        transform="translate(-.421 4.278) scale(.94148)"
      />
      <circle
        r={0.852}
        cx={15.254}
        cy={20.914}
        fill="currentColor"
        transform="translate(-.707 -1) scale(1.03411)"
      />
      <path fill="currentColor" d="M15.054 7.592v8.896" strokeWidth="1.8" />
    </svg>
  )
}

export default WarningIcon
